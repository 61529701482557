<template>
	<!-- alert -->
	<div class="z-10 fixed top-0 w-screen h-screen overflow-hidden flex justify-center">
		<div class="z-10 absolute w-full h-full bg-black opacity-50 backdrop-filter backdrop-blur-md"></div>
		<div class="z-20 m-auto p-5">
			<n-card title="系統訊息" :hideOk="hideOk" :hideCancel="hideCancel" @cancel="$emit('cancel')" @ok="$emit('ok')">
				<div class="flex text-2xl break-all p-3" :class="isError ? 'text-danger' : 'text-primary'">{{ message }}</div>
			</n-card>
		</div>
	</div>
</template>

<script>
import NCard from './NCard.vue'

export default {
	name: 'n-alert',

	props: {
		isError: Boolean,
		message: String,
		hideCancel: Boolean,
		hideOk: Boolean,
	},

	components: {
		NCard,
	},
}
</script>
