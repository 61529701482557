<template>
	<!-- card -->
	<div class="bg-light-gray p-5 rounded-2xl">
		<!-- card header -->
		<div v-if="title" class="text-xl font-bold py-2">{{ title }}</div>
		<!-- card content -->
		<div class="border-2 border-white p-3 py-5 rounded-2xl">
			<slot />
		</div>
		<!-- card footer -->
		<div v-if="!hideCancel || !hideOk" class="flex space-x-2 py-2 h-16 text-lg">
			<n-button v-if="!hideCancel" color="danger" @click="$emit('cancel')">取消</n-button>
			<n-button v-if="!hideOk" @click="$emit('ok')">確認</n-button>
		</div>
	</div>
</template>

<script>
import NButton from './NButton.vue'
export default {
	name: 'n-card',
	props: {
		title: String,
		hideCancel: Boolean,
		hideOk: Boolean,
	},
	components: {
		NButton,
	},
}
</script>
