import { BaseProps } from './shared'

export function Adjustment() {
	return {
		...BaseProps('OPEN'),
		referenceId: null,
		shop: null,
		device: null,
		products: [],
	}
}

export function AdjustmentShop(shop) {
	return {
		id: shop.id,
		code: shop.code,
		name: shop.name,
	}
}

export function AdjustmentDevice(device) {
	return {
		id: device.id,
		name: device.name,
	}
}

export function AdjustmentProduct(catalogItem, units, quantity) {
	return {
		id: catalogItem.id,
		name: catalogItem.product.name,
		units,
		defaultUnit: catalogItem.product.defaultUnit,
		quantity,
	}
}
