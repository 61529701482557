import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const catalog = {
	state: () => ({
		catalog: [],
	}),

	getters: {
		catalog: state => type => {
			if (!type) return state.catalog
			return state.catalog.filter(catalogItem => catalogItem.type === type)
		},
		catalogItem: state => id => {
			return state.catalog.find(catalogItem => catalogItem.id === id)
		},
	},

	actions: {
		bindCatalog: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('catalog')
			return await bindFirestoreRef('catalog', queryRef)
		}),
		unbindCatalog: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('catalog')
		}),
		createCatalogItem: async (store, { catalogItem }) => {
			const createCatalogItem = functions.httpsCallable('createCatalogItem')
			const result = await createCatalogItem({ catalogItem })
			return result.data
		},
		updateCatalogItem: async (store, { catalogItem }) => {
			const updateCatalogItem = functions.httpsCallable('updateCatalogItem')
			const result = await updateCatalogItem({ catalogItem })
			return result.data
		},
		deleteCatalogItem: async (store, { id }) => {
			const deleteCatalogItem = functions.httpsCallable('deleteCatalogItem')
			const result = await deleteCatalogItem({ id })
			return result.data
		},
	},
}
