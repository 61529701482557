<template>
	<div class="flex justify-between w-full p-2" @click="$emit('click')">
		<!-- default start -->
		<div class="flex flex-col w-1/2 text-left">
			<p class="break-all font-bold">{{ start }}</p>
			<div v-if="items" class="py-2">
				<p v-for="item in items" :key="item.id" class="text-md">- {{ item }}</p>
			</div>
		</div>
		<!-- default end -->
		<div class="font-bold">{{ end }}</div>
		<!-- custom start -->
		<slot name="start" />
		<!-- custom end -->
		<slot name="end" />
	</div>
</template>

<script>
export default {
	name: 'n-list-item',

	props: {
		start: String,
		end: String,
		extra: Boolean,
		items: Array,
	},
}
</script>
