import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const shop = {
	state: () => ({
		shops: [],
		currentShop: null,
	}),

	getters: {
		shops: state => {
			return state.shops
		},
		shop: state => id => {
			return state.shops.find(shop => shop.id === id)
		},
		currentShop: state => {
			return state.currentShop
		},
	},

	actions: {
		bindShops: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('shops')
			return await bindFirestoreRef('shops', queryRef)
		}),
		unbindShops: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('shops')
		}),
		createShop: async (store, { shop }) => {
			const createShop = functions.httpsCallable('createShop')
			const result = await createShop({ shop })
			return result.data
		},
		updateShop: async (store, { shop }) => {
			const updateShop = functions.httpsCallable('updateShop')
			const result = await updateShop({ shop })
			return result.data
		},
		bindCurrentShop: firestoreAction(async ({ bindFirestoreRef }, { id }) => {
			let queryRef = db.collection('shops').doc(id)
			return await bindFirestoreRef('currentShop', queryRef)
		}),
		unbindCurrentShop: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('currentShop')
		}),
	},
}
