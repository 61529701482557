<template>
	<div id="app" class="bg-black h-screen w-screen overflow-hidden select-none">
		<n-alert v-if="$store.getters.loading.status" hideOk hideCancel message="載入中" />
		<n-alert v-if="$store.getters.error.status" hideCancel :message="$store.getters.error.message" @ok="closeErrorAlert" />
		<router-view />
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
	async created() {
		if (!localStorage.getItem('deviceId')) localStorage.setItem('deviceId', uuidv4())
		const deviceId = localStorage.getItem('deviceId')
		const device = await this.$store.dispatch('bindCurrentDevice', { id: deviceId })
		if (!device || device.status !== 'ACTIVE') {
			this.$store.dispatch('setLoading', { status: false })
			if (this.$route.path === '/activate') return
			return this.$router.push('/activate')
		}
		await this.$store.dispatch('bindCurrentShop', { id: device.shop.id })
		await this.$store.dispatch('bindCurrentAdjustment', { shopId: device.shop.id, deviceId: device.id })
		await this.$store.dispatch('bindCatalog')
		await this.$store.dispatch('bindInventory', { shopId: device.shop.id })
		this.$store.dispatch('setLoading', { status: false })
		if (this.$route.path === '/signin') return
		return this.$router.push('/signin')
	},

	methods: {
		closeErrorAlert() {
			return this.$store.dispatch('setError', { message: null, status: false })
		},
	},
}
</script>
