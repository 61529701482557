import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/index.css'

Vue.config.productionTip = false

import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)

import NAlert from './components/NAlert'
Vue.component(NAlert.name, NAlert)

import NBadge from './components/NBadge'
Vue.component(NBadge.name, NBadge)

import NButton from './components/NButton'
Vue.component(NButton.name, NButton)

import NCard from './components/NCard'
Vue.component(NCard.name, NCard)

import NListItem from './components/NListItem'
Vue.component(NListItem.name, NListItem)

import NModal from './components/NModal'
Vue.component(NModal.name, NModal)

import NNumberPad from './components/NNumberPad'
Vue.component(NNumberPad.name, NNumberPad)

import NToolbar from './components/NToolbar'
Vue.component(NToolbar.name, NToolbar)

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
