<template>
	<div class="z-10 fixed bottom-0 w-full overflow-hidden">
		<div class="sticky bottom-0 bg-muted w-screen p-5 flex flex-col space-y-3">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'n-modal',
}
</script>
