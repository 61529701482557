<template>
	<div class="flex flex-wrap w-full">
		<div v-for="key in keys" :key="key" class="w-1/3 h-16">
			<div class="active:bg-primary active:text-white mx-auto bg-white w-5/6 h-5/6 p-2 rounded-full flex justify-center items-center text-4xl" @click="$emit('select', key)">
				{{ key }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'n-number-pad',

	props: {
		keys: {
			type: Array,
			default: () => {
				return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '<', '0', 'C']
			},
		},
	},
}
</script>
