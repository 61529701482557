import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const device = {
	state: () => ({
		devices: [],
		currentDevice: null,
	}),

	getters: {
		devices: state => {
			return state.devices
		},
		device: state => id => {
			return state.devices.find(device => device.id === id)
		},
		currentDevice: state => {
			return state.currentDevice
		},
	},

	actions: {
		bindDevices: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('devices')
			return await bindFirestoreRef('devices', queryRef)
		}),
		unbindDevices: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('devices')
		}),
		createDevice: async (store, { device }) => {
			const createDevice = functions.httpsCallable('createDevice')
			const result = await createDevice({ device })
			return result.data
		},
		updateDevice: async (store, { device }) => {
			const updateDevice = functions.httpsCallable('updateDevice')
			const result = await updateDevice({ device })
			return result.data
		},
		deleteDevice: async (store, { id }) => {
			const deleteDevice = functions.httpsCallable('deleteDevice')
			const result = await deleteDevice({ id })
			return result.data
		},
		bindCurrentDevice: firestoreAction(async ({ bindFirestoreRef }, { id }) => {
			let queryRef = db.collection('devices').doc(id)
			return await bindFirestoreRef('currentDevice', queryRef)
		}),
		unbindCurrentDevice: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('currentDevice')
		}),
	},
}
