<template>
	<div class="rounded-full absolute" :class="[classMap[color], classMap[position], classMap[size]]" />
</template>

<script>
export default {
	name: 'n-badge',

	props: {
		color: {
			type: String,
			default: 'primary',
		},
		position: {
			type: String,
			default: 'top-right',
		},
		size: {
			type: String,
			default: 'sm',
		},
	},

	data() {
		return {
			classMap: {
				primary: 'bg-primary text-white active:bg-primary-darkness',
				danger: 'bg-danger text-white active:bg-red-800',
				light: 'bg-white text-black active:bg-primary active:text-white',
				muted: 'bg-white text-muted',
				'top-right': 'right-0 -top-2',
				sm: 'w-5 h-5',
				lg: 'w-10 h-10',
			},
		}
	},
}
</script>
