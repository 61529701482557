import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{ path: '/', redirect: '/activate' },
	{ path: '/activate', component: () => import('../views/Activate.vue') },
	{ path: '/signin', component: () => import('../views/SignIn.vue') },
	{ path: '/menu', component: () => import('../views/Menu.vue') },
	{ path: '/category/:id', component: () => import('../views/Category.vue') },
	{ path: '/product/:id', component: () => import('../views/Product.vue') },
	{ path: '/adjustment', component: () => import('../views/Adjustment.vue') },
	{ path: '/inventory', component: () => import('../views/Inventory.vue') },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
