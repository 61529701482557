import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { shop } from './modules/shop'
import { device } from './modules/device'
import { catalog } from './modules/catalog'
import { inventory } from './modules/inventory'
import { adjustment } from './modules/adjustment'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: {
			status: true,
		},
		error: {
			message: null,
			status: false,
		},
	},

	getters: {
		loading: state => {
			return state.loading
		},
		error: state => {
			return state.error
		},
	},

	mutations: {
		...vuexfireMutations,
		'nube/SET_LOADING': (state, { status }) => {
			state.loading.status = status
		},
		'nube/SET_ERROR': (state, { message, status }) => {
			state.error.message = message
			state.error.status = status
		},
	},

	actions: {
		setLoading: ({ commit }, { status }) => {
			commit('nube/SET_LOADING', { status })
			return true
		},
		setError: ({ commit }, { message, status }) => {
			commit('nube/SET_ERROR', { message, status })
			return true
		},
	},

	modules: {
		shop,
		device,
		catalog,
		inventory,
		adjustment,
	},
})
