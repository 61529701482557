<template>
	<div class="flex p-3 text-lg text-white">
		<div class="flex-1 text-left" @click="$emit('clickLeft')">{{ leftText }}</div>
		<div class="flex-2 w-2/3 text-center">{{ title }}</div>
		<div class="flex-1 text-right" @click="$emit('clickRight')">{{ rightText }}</div>
	</div>
</template>

<script>
export default {
	name: 'n-toolbar',

	props: {
		title: {
			type: String,
			default: '',
		},
		leftText: {
			type: String,
			default: '',
		},
		rightText: {
			type: String,
			default: '',
		},
	},
}
</script>
