import firebase from 'firebase/app'
import PackageInfo from '../package.json'
import 'firebase/firestore'
import 'firebase/functions'

const prodConfig = {
	apiKey: 'AIzaSyBlBlgKKXGOxsyOELVo1axx40VrZNtpBTA',
	authDomain: 'nube-retail-prod.firebaseapp.com',
	projectId: 'nube-retail-prod',
	storageBucket: 'nube-retail-prod.appspot.com',
	messagingSenderId: '994491951644',
	appId: '1:994491951644:web:66df911a3b9903d6964dd9',
	measurementId: 'G-DWY51MVGFF',
}

const devConfig = {
	apiKey: 'AIzaSyAEZiD8o6XxmtJPgFbuP9nvJSillruq6Rc',
	authDomain: 'nube-retail-dev.firebaseapp.com',
	projectId: 'nube-retail-dev',
	storageBucket: 'nube-retail-dev.appspot.com',
	messagingSenderId: '121610753711',
	appId: '1:121610753711:web:362121cbe5d8744a5a265a',
	measurementId: 'G-CQ1ND4YYRW',
}

firebase.initializeApp(PackageInfo.production ? prodConfig : devConfig)

export const db = firebase.firestore()
export const functions = firebase.app().functions('asia-east2')

if (PackageInfo.emulator) {
	db.useEmulator('localhost', 5002)
	functions.useEmulator('localhost', 5001)
}
