<template>
	<button class="flex justify-center items-center w-full h-full rounded-xl font-medium break-all px-2 cursor-not-allowed" :class="[classMap[color]]" @click="$emit('click')">
		<slot />
	</button>
</template>

<script>
export default {
	name: 'n-button',

	props: {
		color: {
			type: String,
			default: 'primary',
		},
	},

	data() {
		return {
			classMap: {
				primary: 'bg-primary text-white active:bg-primary-darkness',
				danger: 'bg-danger text-white active:bg-red-800 ',
				light: 'bg-white text-black active:bg-primary active:text-white',
				muted: 'bg-white text-muted active:bg-primary active:text-white',
			},
		}
	},
}
</script>
